<template lang="pug">
	article#politicaPrivacidade
		section#politica
			.wrapper
				div
					h2 Políticas de Privacidade
					div(v-html="politica.secoes.politica.conteudo")
</template>

<script>
export default {
	data() {
		return {
			politica: null,
		}
	},
	created() {
		this.loadPoliticas()
	},
	methods: {
		loadPoliticas() {
			this.$axios
				.get(`api/politica-privacidade`)
				.then(response => {
					this.politica = response.data;
				})
		},
	},
}
</script>

<style lang="stylus" scoped src="./PoliticaDePrivacidade.styl">
</style>